import { SegmentPayload } from './types';
import { Cookies } from 'react-cookie';
import { handleAnalytics } from './index';
import { COOKIE_CAMPAIGN_CODES, INT_CMP, COOKIE_CODE_INFO, Brand } from 'constants/index';
import { cleanObject } from 'lib/utils/helper';
import { getConfig } from 'lib/utils/getConfig';

export enum EventTypes {
  PAGE = 'page',
  TRACK = 'track',
}

export enum Events {
  BUTTON_LINK_SELECTED = 'Button Link Selected',
  SUBMIT = 'Submit',
  SUBMIT_CODE_LANDING = 'Submit Code Landing',
  SUBMIT_CODE_STARTED = 'Submit Code Started',
  SUBMIT_CODE_COMPLETED = 'Submit Code Completed',
  PROFILE_ACCESS_ACTIVATION_STARTED = 'Profile Access Activation Started',
  PROFILE_ACCESS_ACTIVATION_COMPLETED = 'Profile Access Activation Completed',
  PROFILE_ACCESS_ACTIVATION_ERROR = 'Profile Access Activation Error',
  PROFILE_INITIATED = 'Profile Initiated',
  PROFILE_PROMPT_LANDING = 'Profile Prompt Landing',
  PROFILE_SIGN_IN_STARTED = 'Profile Sign In Started',
  PROFILE_SIGN_UP_STARTED = 'Profile Sign Up Started',
  PROFILE_SIGN_IN_COMPLETED = 'Profile Sign In Completed',
  PROFILE_SIGN_UP_COMPLETED = 'Profile Sign Up Completed',
}

const FOX_PREFIX = 'delta';
const NATION_PREFIX = 'nation';
const SPORT_PREFIX = 'nation sport';

export enum PageNames {
  SUBMIT_CODE_LANDING = 'submit code landing',
  PROFILE_PROMPT_LANDING = 'profile prompt:landing',
}

export enum PageTypes {
  ACTIVATION_CODE = 'activation code',
  PROFILE_PROMPT = 'profile prompt',
}

const getClientPrefix: AnyObject = {
  [Brand.FOX]: FOX_PREFIX,
  [Brand.SPORTS]: SPORT_PREFIX,
  [Brand.NATION]: NATION_PREFIX,
};

const ACTIVATION_METHOD = '2nd screen';
const APP_PLATFORM = 'web';
const FOX = 'fox';
const FOX_DOMAIN = 'fox.com';
const NATION_DOMAIN = 'foxnews.com';
const ANONYMOUS_ID_COOKIE = 'lastAnonymousProfileId';
const PAGE_TYPE = 'ott';
const PRIMARY_BUSINESS_UNIT = 'fng';
const PROFILE_ID_COOKIE = 'secondScreenProfileId';
const START_SOURCE = 'general';
const APP_CLIENT_PREFIX = getClientPrefix[process.env.NEXT_PUBLIC_BRAND as string];

const defaultTraits = {
  appsflyer_id: '',
  dcg_profile_id: '',
  lastAnonymousProfileId: '',
  lastKnownProfileId: '',
  mvpd: '',
  network_entitlement_list: '',
  us_privacy: '',
};

// TODO: Update BRAND and DEVICE for Nation
// const DEVICE = account?.device ?? 'FOX';
const BRAND = FOX;
const DEVICE = 'FOX';

const defaultProps = {
  app_name: process.env.NEXT_PUBLIC_BRAND || BRAND,
  app_platform: APP_PLATFORM,
  // TODO: set app version dynamically
  app_version: '1.0.0',
  page_name: ACTIVATION_METHOD,
  page_profile_activation_method: ACTIVATION_METHOD,
  page_start_source: START_SOURCE,
  page_type: PAGE_TYPE,
  primary_business_unit: PRIMARY_BUSINESS_UNIT,
  secondary_business_unit: process.env.NEXT_PUBLIC_BRAND || BRAND,
};

export const handleTrackEvent = (...events: Array<SegmentPayload>): void => {
  for (const { eventName, props, context, eventType } of events) {
    const cookies = new Cookies();
    const profileId = cookies.get(PROFILE_ID_COOKIE) ?? '';
    const codeInfoCookie = cookies.get(COOKIE_CODE_INFO) ?? {};

    let campaignCodes = fetchCampaignCodes();

    // TODO: Find out why campaignCodes are different for different events
    if (eventName === Events.PROFILE_ACCESS_ACTIVATION_COMPLETED) {
      const compaignCodesCookie = cookies.get(COOKIE_CAMPAIGN_CODES) ?? '';
      campaignCodes = parseCampaignParams(compaignCodesCookie);
    }
    const traits = {
      ...defaultTraits,
      ...campaignCodes,
      dcg_profile_id: profileId,
      lastAnonymousProfileId: getAnonymousId(),
      lastKnownProfileId: profileId,
    };

    const argsPageName = props?.page_name ?? null;
    const currentPageName = window.DataLayer?.page_name ?? null;
    const pageName = argsPageName ?? currentPageName;
    const genericPageName = generatePageName({ pageName });

    const argsPageType = props?.page_type ?? null;
    const currentPageType = window.DataLayer?.page_type ?? null;
    const pageType = argsPageType ?? currentPageType;

    const argsActivationCode = props?.page_activation_code ?? null;
    const currentActiovationCode = codeInfoCookie.code ?? null;
    const activationCode = argsActivationCode ?? currentActiovationCode;

    const newContext = {
      ...context,
      traits,
    };

    const finalProps = cleanObject({
      ...defaultProps,
      page_name: genericPageName,
      page_type: pageType,
      page_activation_code: activationCode,
      ...props,
    });

    handleAnalytics({
      eventName,
      props: finalProps,
      eventType,
      context: newContext,
    });
  }
};

export const generatePageName = ({ pageName }: { pageName: string }): string =>
  `${APP_CLIENT_PREFIX}:${APP_PLATFORM}:${pageName}`;

export const handleHomePageEvent = (): void => {
  handleTrackEvent(
    {
      eventName: Events.PROFILE_ACCESS_ACTIVATION_STARTED,
    },
    {
      eventName: Events.SUBMIT_CODE_STARTED,
    },
    {
      eventName: Events.SUBMIT_CODE_LANDING,
      eventType: EventTypes.PAGE,
    },
  );
};

export const handleAccountPageEvent = (): void => {
  handleTrackEvent({
    eventName: Events.PROFILE_PROMPT_LANDING,
    eventType: EventTypes.PAGE,
  });
};

const getAnonymousId = () => {
  const cookies = new Cookies();
  const user = window.analytics?.user;
  const lastAnonymousProfileId = cookies.get(ANONYMOUS_ID_COOKIE) ?? '';

  let networkDomain;
  if (BRAND === FOX) networkDomain = FOX_DOMAIN;
  else networkDomain = NATION_DOMAIN;

  if (lastAnonymousProfileId) {
    cookies.set(ANONYMOUS_ID_COOKIE, lastAnonymousProfileId, { domain: networkDomain });
    return lastAnonymousProfileId;
  }

  const anonymousId = user ? user().anonymousId() : null;

  // TODO: Finds out why it is here
  cookies.set(ANONYMOUS_ID_COOKIE, anonymousId, { domain: networkDomain });
  return anonymousId;
};

export const mountDataLayer = (): void => {
  window.DataLayer = {};
};

export const setPageAnalyticsData = ({
  pageName,
  pageType,
}: {
  pageName: string;
  pageType: string;
}): void => {
  if (!window.DataLayer) mountDataLayer();
  window.DataLayer.page_name = pageName;
  window.DataLayer.page_type = pageType;
};

const parseCampaignParams = (queryString: string) => {
  if (!queryString) return {};
  const cleanQueryString = queryString.split('undefinedUnknown')[0];
  const segmentCampaign = 'cmpid';

  const propertyNames: { [index: string]: string } = {
    cmpid: 'campaign_int_tracking_code',
    int_cmp: 'campaign_int_name',
  };

  const params: AnyObject = {};
  const cleanCampaignString = cleanQueryString.match(/^[^&]*/) ?? '';
  const campaignString = cleanCampaignString[0] ?? '';

  params[propertyNames[segmentCampaign]] = campaignString;

  const campaignCodes = campaignString.split('::');

  (campaignCodes || []).forEach((codes) => {
    const keyValuesPair = codes.split('=');
    if (keyValuesPair.length === 2) {
      const key = keyValuesPair[0] ?? '';
      const value = keyValuesPair[1] ?? '';
      const property = propertyNames[key] ?? '';
      params[property] = value;
    }
  });
  return cleanObject(params);
};

const fetchCampaignCodes = () => {
  const intCmp = getParameterByName(INT_CMP) ?? null;
  // if (intCmp == null) return null;
  const intOrg = 'dcg';
  // TODO: Get brand from
  const intMc = BRAND;
  const intSrc = getDeviceFromProvidersByKey() ?? DEVICE;
  const intAdd = intMc + (intSrc ? '_' + intSrc : '') + (intCmp ? '_' + intCmp : '');
  const codes = cleanObject({
    int_org: intOrg,
    int_mc: intMc ?? null,
    int_src: intSrc ?? null,
    int_cmp: intCmp ?? null,
    int_add: intAdd ?? null,
  });
  codes.campaign_int_tracking_code = formatCampaignString(codes);
  return codes;
};

const getParameterByName = function (name: string): string | null {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(window?.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

const formatCampaignString = (campaignCodes: AnyObject) => {
  const campaignKeys = Object.keys(campaignCodes);
  return campaignKeys.reduce(function (acc, key, i) {
    const isLast = i === campaignKeys.length - 1;
    return acc + key + '=' + campaignCodes[key] + (!isLast ? '::' : '');
  }, '');
};

const getDeviceFromProvidersByKey = () => {
  const cookies = new Cookies();
  const codeInfoCookie = cookies.get(COOKIE_CODE_INFO) ?? '';

  const config = getConfig();
  const devices = config?.devices ?? [];

  Object.entries(devices).forEach((element) => {
    const deviceName = element[0];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const providerKeys = element[1]['providers-key'];
    if (providerKeys === codeInfoCookie || deviceName === providerKeys) return element;
  });
  return null;
};
